//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

@import 'react-toastify/dist/ReactToastify.css';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

@import 'layout/theme';
